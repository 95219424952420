<template>
  <div></div>
</template>
<script>
import { getAppId } from '@/api/Login/Choose'
export default {
  name: 'Choose',
  data() {
    return {
      appId: null
    }
  },
  created() {
    // 判断是否在微信内部打开
    this.chooseBrowser()
  },
  methods: {
    // 判断是否在微信内部打开
    chooseBrowser() {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('micromessenger') !== -1) {
        // 获取微信appID
        this.getAppId()
      } else {
        // alert('请在微信内部打开')
        this.$router.push('/Login')
      }
    },
    // 获取appID
    async getAppId() {
      const res = await getAppId()
      this.appId = res.data.appid
      // 获取用户code
      this.getUserCode()
    },
    // 获取code
    async getUserCode() {
      const url = encodeURIComponent('http://employee.zfbhelper.com/yunketang/#/index')
      window.location.href =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appId + '&redirect_uri=' + url + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
    }
  }
}
</script>
